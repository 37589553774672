.cart {
  $rowSizing: 45%;
  &-page {
    margin: 0px 67px;
    margin-top: $headerHeight + 20;
    max-width: 100vw;

    padding-top: 50px;
    position: relative;

    @include md {
      padding-top: 120px;
      margin: 0px 15px;
    }

    .configurator-buttonCon.--cart {
      padding-top: 70px;
      position: relative;
      // margin-top: -25px;
      padding-bottom: 50px;

      @include md {
        background: $whiteColor;
        position: fixed;
        bottom: 0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        padding: 22px 27px 25px;
      }
    }
    .swiper-pagination {
      bottom: -15px;
      @include md {
        bottom: 15px;
      }
    }

    .cart-modelBG {
      .swiper-container {
        height: 41vh;
        padding-bottom: 150px;
        @include md {
          height: unset;
          padding-bottom: 80px;
        }
      }
      .configurator-buttonCon {
        padding-top: 0px;
        position: relative;
        margin-top: -25px;
        padding-bottom: 10px;
        @include md {
          padding-bottom: 5px;
          margin-top: 0;
        }
      }

      .configurator-ingredient {
        padding: 3px;
      }
    }

    .configurator-slideTitle {
      text-align: left;
    }
  }
  &-con {
    background: $whiteColor;
    padding: 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    margin-bottom: 30px;

    &.--summary {
      // @include md {
      //   margin-bottom: 120px;
      // }
    }

    &.--shippingOption {
      padding: 0px;
      padding-top: 0;
    }
  }
  &-item {
    border-radius: 10px;
    // overflow: hidden;
  }
  &-allergyNote {
    padding: 20px 28px 50px;

    color: #646464;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;

    font-family: "Brandon-Grotesque";
    font-style: italic;
    font-weight: 300;

    @include md {
      padding: 20px 30px 50px;
      font-size: 12px;
    }

    span {
      font-weight: 600;
    }
  }

  &-leftRule {
    @extend %absolute-cover;

    padding: 0px;
    pointer-events: none;
  }

  &-leftInnerRule {
    border-right: 2px solid #e8e6df;
    height: 100%;
    width: $rowSizing;
    @include md {
      width: 67px;
      &.--summary {
        width: 60%;
      }
    }
  }
  &-sync {
    width: 80%;
    height: 100%;
  }
  &-itemHeader {
    border-bottom: 2px solid #e8e6df;
    display: flex;
    width: 100%;
    padding: 20px 30px;
    align-items: center;
    background: $whiteColor;
    position: relative;
    border-radius: 10px 10px 0px 0px;
    z-index: 1;

    @include md {
      padding: 10px 15px;
    }

    &-name {
      font-size: 36px;
      line-height: 51px;
      letter-spacing: 0.25px;
    }
    &.--summary {
      padding: 15px 0;
      margin: 00px 30px;
      width: calc(100% - 60px);

      @include md {
        padding: 10px 0;
      }
    }
  }

  &-name {
    font-size: 36px;
    line-height: 51px;
    letter-spacing: 0.25px;
    font-family: "Brandon-Grotesque";
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 27px - 14;

    @include md {
      font-size: 24px;
    }
    &.--summary {
      font-size: 28px;
      letter-spacing: 0.25px;
      color: $redColor;
      font-family: "Goudy-Sans";
      @include md {
        margin-left: 0px;
        font-size: 22px;
      }
    }
  }
  &-x {
    padding: 14px;
    @include md {
      padding: 13px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  &-expandIcon {
    position: absolute;
    right: 20px;
    padding: 30px;
    @include md {
      right: 0px;
      padding: 30px;
    }
    svg {
      transition: transform 0.3s;
    }
    @include md {
      svg {
        width: 15px;
        height: 8.5px;
      }
    }
  }
  &-editButton {
    margin-left: 14px;
    margin-right: 20px;

    @include md {
      margin-left: 0px;
    }
  }

  &-rowSmall {
    display: flex;
    flex-wrap: wrap;
    width: $rowSizing;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #b88b74;
    font-family: "Goudy-Sans";
    text-transform: capitalize;
    @include md {
      width: 100%;
    }

    > p {
      @include md {
        padding-left: 18px;
        font-size: 20px;
      }
    }
    &.--priceTitle {
      text-transform: uppercase;
      color: $brownColor;
      font-size: 24px;
      padding-left: 50px;
      font-family: "Brandon-Grotesque";
      font-weight: 900;
      letter-spacing: 1px;
      @extend %flexContent-center;
      justify-content: left;

      @include md {
        display: none;
      }
    }

    &.--summary {
      text-align: right;
      padding: 0px 30px 0 10px;
      @include md {
        width: 61%;
        padding: 0px 20px 0 10px;
      }
      > p {
        @include md {
          text-align: right;
          padding-left: 0px;
          margin-left: auto;
        }
      }
      .cart-editButton {
        opacity: 0;
        display: none;
      }
    }
  }

  &-rowBig {
    width: 100 - $rowSizing;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.25px;
    color: #3f281e;
    font-family: "Brandon-Grotesque";
    font-weight: 400;
    padding-left: 30px;

    @include md {
      width: 100%;
      padding-left: 66px;
      padding-top: 3px;
    }
    &Price {
      width: 50%;
      border-left: 4px dotted #e8e6df;
      @extend %flexContent-center;
      color: $brownColor;
      font-size: 30px;
      letter-spacing: 0.5px;
      font-family: "Brandon-Grotesque";
      font-weight: 900;
      @include md {
        border-left: 5px dotted #e8e6df;
        font-size: 24px;
        text-align: center;
      }
    }
    &.--flex {
      display: flex;
      padding-left: 0;

      p {
        width: unset;
        padding-left: 0 !important;
      }
    }
    > p {
      width: 100%;
      display: flex;
      position: relative;
      @include md {
        padding-left: 30px;
        font-size: 16px;
        line-height: 23px;
        padding-top: 3px;
      }
    }

    &.--summary {
      @include md {
        padding-top: 0;
        padding-left: 20px;

        width: 39%;
      }
      > p {
        font-family: "Brandon-Grotesque";
        font-weight: 500;
        font-size: 23px;
        line-height: 33px;
        color: $brownColor;
        @include md {
          padding-bottom: 5px;
          font-size: 16px;
          line-height: 16px;
          padding-top: 5px;
          padding-left: 0;
        }
      }
    }
  }

  &-extra {
    // position: absolute !important;
    // right: 0;
    display: block;
    padding-left: 20px;
    font-family: "Brandon-Grotesque";
    font-weight: 700;
    color: $brownMDColor;
    font-size: 14px;
    letter-spacing: 0.25px;

    @include md {
      margin-left: auto;
    }
  }
  &-ingredients {
    // min-height: 20px;
    background: rgba(#7299c6, 0.05);
    overflow: hidden;
    max-height: 900px;
    transition: transform 0.3s, margin 0.2s, max-height 0.3s ease-out;

    &.--summary {
      background: none;
      // padding: 30vw;
    }
  }

  &-lineItem {
    display: flex;
    border-bottom: 4px dotted #e8e6df;
    padding: 22px 0px 22px;
    margin: 0px 35px;
    @include md {
      display: block;
      margin: 0px 25px;
      &.--summary {
        display: flex;
        padding: 10px 0;
      }
    }
    &:last-child {
      border-bottom: unset;
    }
  }

  &-footer {
    width: 100%;
    padding: 10px 0;
    display: flex;
    border-top: 2px solid #e8e6df;

    @include md {
      position: relative;
      z-index: 9;
      background: $whiteColor;
      padding: 0;
      border-radius: 0px 0px 10px 10px;
      padding-bottom: 2px;
    }
    &.--summary {
      padding: 20px 0;
      margin: 00px 30px;
      width: calc(100% - 60px);

      @include md {
        padding: 0;
        background: unset;
      }
    }
  }

  &-empty {
    padding: 60px 30px;
    font-family: "Brandon-Grotesque";
    font-weight: 700;
  }

  &-modelX {
    position: absolute;
    right: 30px;
    top: 30px;

    @include md {
      right: 12px;
      top: 10px;
      padding: 10px;
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  &-shippingOption {
    @extend %flexContent-spaceBetween;
    padding: 10px 20px 20px;
    @include md {
      // display: block;
    }
    @include md {
      margin-bottom: 120px;
    }
    &Con {
      width: calc(90% - 15px);
    }
    &Item {
      background: white;
      position: relative;
      text-align: center;
      border-radius: 10px;
      @extend %flexContent-spaceAround;
      justify-content: center;
      flex-direction: column;
      padding: 30px;
      // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      // border: 4px solid $redColor;
      cursor: pointer;
      transition: opacity 0.3s, filter 0.3s, transform 0.2s;

      &:active {
        transform: scale(0.95);
      }

      @include md {
        // width: calc(50% - 1px);
        margin-bottom: 10px;
        padding: 20px;
      }

      .configurator-ingredientRing {
        position: absolute;
        z-index: 1;
        transform: scale(0.8);

        path {
          fill: white;
        }
        &.--2nd {
          z-index: 1;
          transform: scale(0.865);
          // margin: -3px;
          path {
            fill: #e31b23;
          }
        }
      }
      &Img {
        width: 50%;
        // height: 50%;
        object-fit: cover;
        position: relative;
        z-index: 9;
        // background: grey;
        margin-bottom: 5px;
        @include md {
          width: 70%;
          // height: 50%;
        }
      }

      &Tile {
        margin: auto;
        width: 100%;
        text-align: center;
        margin-top: 5px;
        padding-bottom: 10px;
        position: relative;
        z-index: 9;
        color: #3f281e;

        font-weight: 700;
        font-size: 20px;
        @include md {
          margin-top: 0px;
          font-size: 16px;
        }
      }
    }
  }
}

.cart {
  &-count {
    &Con {
      width: 50%;
      padding: 19px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include md {
        padding: 13px 0;
      }
    }
  }
  &-countText {
    padding: 0 25px;
    font-size: 48px;
    letter-spacing: 0.5px;
    color: #3f281e;
    font-family: "Brandon-Grotesque";
    font-weight: 900;

    @include md {
      font-size: 24px;
    }
  }
}

.cartSummary {
  &-sub {
    font-family: "Brandon-Grotesque";
    width: 100%;
    padding: 5px 0;
    letter-spacing: 0.25px;
    font-weight: 500;
    font-size: 23px;
    line-height: 33px;
    color: $brownColor;

    @include md {
      font-size: 16px;
      line-height: 23px;
      padding: 2px 0;
      padding-top: 10px;
    }
  }

  &-tax {
    font-family: "Brandon-Grotesque";
    width: 100%;
    padding: 5px 0;
    font-weight: 500;
    font-size: 23px;
    line-height: 33px;
    /* identical to box height */
    letter-spacing: 0.25px;
    color: #848484;
    @include md {
      font-size: 16px;
      line-height: 23px;
      padding: 2px 0;
    }
  }
  &-total {
    font-family: "Brandon-Grotesque";
    width: 100%;
    padding: 15px 0 10px;
    font-weight: 900;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: 0.25px;
    color: $brownColor;
    @include md {
      font-size: 24px;
      line-height: 34px;
      padding: 15px 0 20px;
    }
  }
}

.cart-contMinus,
.cart-countPluse {
  @extend %flexContent-center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: $blueColor;

  @include md {
    width: 20px;
    height: 20px;

    svg {
      padding-left: 0.5px;
      width: 11px;
    }
  }
}

.cart-countPluse {
  @include md {
    svg {
      width: 11px;
      height: 11px;
    }
  }
}
