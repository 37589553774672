.seal {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
  @extend %flexContent-center;

  @include md {
    width: 40px;
    height: 40px;
  }
  &.--bigMobile {
    @include md {
      width: 80px;
      height: 80px;
    }
  }

  &.--smallNumber {
    .seal-text {
      font-size: 48px;
      letter-spacing: 0.5px;
    }
  }

  .seal-text {
    margin-left: 1px;
    .lds-roller {
      transform: scale(0.6);
      div:after {
        background: $whiteColor;
      }
    }
  }
  &-icon {
    @extend %absolute-cover;
    // z-index: 0;
    width: 100%;
    height: 100%;
    path {
      fill: $redColor;
      transition: fill 0.3s;
    }
  }

  &-text {
    color: $bodyColor;
    font-weight: 900;
    font-size: 64px;
    line-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Brandon-Grotesque";
    font-weight: 900;
    @extend %flexContent-center;
    @include md {
      font-size: 30px;
      width: 25px;
    }
  }
}

// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
// @keyframes spinAnti {
//   0% {
//     transform: translate(-50%, -50%) rotate(0deg);
//   }
//   100% {
//     transform: translate(-50%, -50%) rotate(-360deg);
//   }
// }
