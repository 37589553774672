@font-face {
  font-family: "MagnesiumSTD";
  font-display: swap;

  src: url("fonts/Magnesium/MagnesiumMVBStd.woff") format("woff");
}

@font-face {
  font-family: "Brandon-Grotesque";
  font-weight: 300;
  font-display: swap;

  src: url("fonts/Brandon_Grotesque/BrandonGrotesque-Regular.woff")
    format("woff");
}
@font-face {
  font-family: "Brandon-Grotesque";
  font-weight: 500;
  font-display: swap;

  src: url("fonts/Brandon_Grotesque/BrandonGrotesque-Medium.woff")
    format("woff");
}
@font-face {
  font-family: "Brandon-Grotesque";
  font-weight: 500;
  font-style: italic;
  font-display: swap;

  src: url("fonts/Brandon_Grotesque/BrandonGrotesque-MediumItalic.woff")
    format("woff");
}

@font-face {
  font-family: "Brandon-Grotesque";
  font-weight: 700;
  font-display: swap;

  src: url("fonts/Brandon_Grotesque/BrandonGrotesque-Bold.woff") format("woff");
}
@font-face {
  font-family: "Brandon-Grotesque";
  font-weight: 900;
  font-display: swap;

  src: url("fonts/Brandon_Grotesque/BrandonGrotesque-Black.woff") format("woff");
}

@font-face {
  font-family: "Goudy-Sans";
  src: url("fonts/Goudy-Sans/GoudySansStd-BoldItalic.woff") format("woff");
  font-display: swap;
}
