@use "sass:math";
.homeHero {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);

  position: relative;
  z-index: 11;
  @extend %flexContent-center;
  flex-wrap: nowrap;

  &-innerCon {
    padding: 30px 30px;
  }
  &-imgCoverCon {
    @extend %absolute-cover;
    position: fixed;
    background: $bodyColor;
    min-width: 100vw;
    min-height: 100vh;
    z-index: -1;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &-imgCover {
    transform: translateX(0%);
    animation: scroll 75s linear infinite;
  }

  &-logo {
    margin: auto;
    width: 90%;
    max-width: 600px;
    display: block;
    margin-bottom: 20px;
    // height: 150px;
    object-fit: contain;
    @include md {
      height: unset;
      max-width: 300px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  }

  &-textGrid {
    max-width: 700px;
    width: 90vw;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: space-evenly;
    grid-column-gap: 18px;
    grid-row-gap: 18px;

    @include md {
      display: block;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      max-width: 400px;
      margin: auto;
      border-radius: 10px;
      background: $whiteColor;
    }
  }

  &-textCon {
    &.--Large {
      padding: 20px;
      background: $whiteColor;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      grid-column: 1 / 4;
      @include md {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
        padding: 10px 10px;
        margin-bottom: 30px;
      }
    }
    &.--Small {
      padding: 35px;
      background: $whiteColor;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      @include md {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0);
        padding: 0 0px 10px;
        margin: 0 30px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 4px dotted #e8e6df;
        display: none;

        &:last-of-type {
          border-bottom: 0px dotted #e8e6df;
          padding-bottom: 25px;
        }
        .seal {
          margin: 0px;
          margin-right: 10px;
        }
      }
    }
  }

  &-headline {
    padding: 20px 0 12px;
  }

  &-copy {
    font-size: 20px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.5px;
    max-width: 529px;
    color: $brownColor;
    margin: auto;
    padding-bottom: 20px;
    font-family: "Brandon-Grotesque";

    @include md {
      font-size: 18px;
      font-size: min(calc(7vw - 8px), 18px);
      letter-spacing: 0.5px;
      line-height: math.div(26, 18);
      letter-spacing: 0.5px;
      padding: 0px 10px;
      padding-bottom: 20px;
    }
  }

  //smaller cards

  &-stepText {
    font-family: "Brandon-Grotesque";
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.5px;
    color: $redColor;

    margin-bottom: 10px;

    @include md {
      display: none;
    }

    .seal-text {
      color: $bodyColor;
    }
  }

  &-rule {
    $dotSize: 3px;
    height: $dotSize * 2;
    margin: 28px 0 15px;
    // border-bottom: 4px dotted #e8e6df;
    border: none;

    background-image: radial-gradient(
        circle at $dotSize,
        #e8e6df $dotSize / 2,
        rgba(255, 255, 255, 0) $dotSize
      ),
      radial-gradient(circle, #e8e6df 1.25px, rgba(255, 255, 255, 0) $dotSize),
      radial-gradient(
        circle at $dotSize,
        #e8e6df $dotSize / 2,
        rgba(255, 255, 255, 0) $dotSize
      ),
      radial-gradient(
        circle,
        #e8e6df $dotSize / 2,
        rgba(255, 255, 255, 0) $dotSize
      );
    background-position: top;
    background-size: $dotSize * 4.2 $dotSize * 2, 0px 0px;
    background-repeat: repeat-x;
  }

  &-stepTitle {
    color: $blueColor;
    font-family: "Goudy-Sans";
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.5px;

    @include md {
      font-size: 24px;

      line-height: math.div(29, 24);
      align-items: left;
    }
  }

  button.--big {
    margin-top: 40px;
    @include md {
      margin-top: 21px;
      margin-bottom: 20px;
    }
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}
