.buttonMain {
  height: 40px;
  width: 160px;
  // background: $redColor;
  color: $bodyColor;
  font-weight: bold;
  font-size: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-align: center;
  // clip-path: $chevronPathRight;
  transition: all 0.3s;
  font-family: "Brandon-Grotesque";
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  background: none;
  z-index: 99;
  @include md {
    height: 34px;
    width: 36vw;

    font-size: 12px;
    font-size: 3.2vw;
  }
  svg {
    @extend %absolute-cover;
    z-index: -99;
    // width: 160px;
    // height: 40px;
    path {
      fill: $redColor;
      transition: fill 0.3s;
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &-inner {
    margin: auto;
  }

  &.--left {
    path {
      fill: $blueColor;
      transition: fill 0.3s;
    }
  }
  &.--big {
    height: 75px;
    width: 300px;
    font-size: 30px;
    letter-spacing: 1px;

    text-transform: uppercase;
    font-family: "Brandon-Grotesque";
    font-weight: 700;

    animation: breathing 5s ease-in-out infinite normal;
    display: block;
    margin: auto;
    @include md {
      height: 50px;
      width: 200px;
      font-size: 20px;
    }
    &:active {
      transform: scale(0.96);
    }

    svg {
      // height: 75px;
      // width: 300px;
    }
  }
  &.--center {
    clip-path: none;

    position: relative;
    background: none;
    width: 200px;
    height: 40px;

    text-align: center;

    @include md {
      display: none;
    }

    &-inner {
      text-align: center;
      margin: auto;
    }
    svg {
      @extend %absolute-cover;
      z-index: -99;
      // width: 200px;
      // height: 40px;
      path {
        fill: $brownMDColor;
      }
    }
  }
  &.is-disabled {
    pointer-events: none;

    svg {
      path {
        fill: #f8f5e2;
      }
    }
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
