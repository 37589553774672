.preview {
  &-con {
    box-sizing: border-box;
    width: 100%;
    max-height: 30vh;
    padding-left: 50px;
    padding-right: 50px;
    transform: scale(1);
    // display: flex;
    // align-items: flex-end;
    // flex-wrap: wrap;
    // display: grid;
    // place-items: center end;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block;
      width: 70%;
      margin: auto;
      transition: opacity 0.3s;
    }
  }
}

.slice {
  &-con {
    width: 100%;
    justify-content: center;
    align-content: center;
    display: flex;
    height: 33px;
    position: relative;
    z-index: 2;
    transition: all 0.3s;

    &.--top {
      position: relative;
      z-index: 2;
      margin-top: 0px;
      height: 95px;
      margin-bottom: -7px;

      .slice-imgCon {
      }
    }
    &.--bottom {
      position: relative;
      z-index: 1;
      margin-bottom: 25px;
    }

    &.--over {
      z-index: 99;
    }

    @for $i from 1 through 9 {
      &:nth-child(#{$i}) .slice-imgCon {
        animation-delay: -0.2s * $i;
      }
    }
  }

  &-imgCon {
    width: 40%;
    position: relative;
    animation-name: floating;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  &-img {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 100%;
    // filter: drop-shadow(0 0 0.2rem rgba(black, 0.2));
  }
  &-text {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 35%;
    margin-left: 50px;
  }
  &-title {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    $dotSize: 1.5px;
    height: $dotSize * 2;
    background-image: radial-gradient(
        circle at $dotSize,
        $brownMDColor $dotSize / 2,
        rgba(255, 255, 255, 0) $dotSize
      ),
      radial-gradient(
        circle,
        $brownMDColor 1.25px,
        rgba(255, 255, 255, 0) $dotSize
      ),
      radial-gradient(
        circle at $dotSize,
        $brownMDColor $dotSize / 2,
        rgba(255, 255, 255, 0) $dotSize
      ),
      radial-gradient(
        circle,
        $brownMDColor $dotSize / 2,
        rgba(255, 255, 255, 0) $dotSize
      );
    background-position: bottom;
    background-size: $dotSize * 7.1 $dotSize * 2, 0px 0px;
    background-repeat: repeat-x;

    p {
      height: max-content;
      width: 100%;
      text-align: right;
      font-size: 14px;
      padding-bottom: 26px;
      font-family: "Brandon-Grotesque";
      font-weight: 500;
      letter-spacing: 0.25px;
    }
  }
}

@keyframes floating {
  0% {
    transform: translate(0, -5px);
  }

  50% {
    transform: translate(0, 5px);
  }

  100% {
    transform: translate(0, -5px);
  }
}
