// @extend classes

%flexContent-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%grid-center {
  display: grid;
  place-items: center;
}

%flexContent-spaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
%flexContent-spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%absolute-cover {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

// @include mixins

// Small Phone
$screen-xs-max: 350px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;
// Small tablets (portrait view)
$screen-md-max: 810px;
// Tablets and small desktops
$screen-lg-max: 992px;
// Large tablets and desktops
$screen-xl-max: 1200px;
// Big desktops
$screen-xxl-max: 1920px;

@mixin xs {
  @media (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$screen-md-max - 1}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}
