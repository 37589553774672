.configurator {
  padding: 0 10px 0 10px !important;
  width: 100%;
  @include md {
    height: max-content;
    display: block;
    padding-bottom: 100px !important;
  }

  &-con {
    margin-top: $headerHeight;
    height: calc(100vh - #{$headerHeight});
    overflow-y: visible;
    @include md {
      height: unset;
    }
  }

  &-productDisplay {
    height: calc(47.5vh - #{$headerHeight--steps});
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    @include md {
      display: none;
    }
  }

  .swiper-slide {
    @include md {
      height: 100%;
    }
  }

  $slidePadding: 30px;

  &-slide {
    background: $whiteColor;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    height: calc(52.5vh);
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    padding: $slidePadding;
    position: relative;

    @include md {
      height: 100%;
      padding: 20px;
      overflow: hidden;
      max-width: 500px;
      margin: auto;
      border-radius: 10px;
    }
  }

  &-slide {
    &Copy {
      font-family: "Brandon-Grotesque";
      font-weight: 300;
      color: #848484;
      letter-spacing: 0.25px;
      font-size: 16px;
      line-height: 23px;
      @include md {
        font-size: min(calc(2.95vw), 11px);

        // font-size: 11px;
        line-height: 1.3;
      }
    }

    &Rule {
      $dotSize: 2.5px;
      height: $dotSize * 2;

      margin: 10px 60px 20px;

      @include md {
        margin: 10px 12px 20px;
      }
      // border-bottom: 4px dotted #e8e6df;
      border: none;

      background-image: radial-gradient(
          circle at $dotSize,
          #e8e6df $dotSize / 2,
          rgba(255, 255, 255, 0) $dotSize
        ),
        radial-gradient(circle, #e8e6df 1.25px, rgba(255, 255, 255, 0) $dotSize),
        radial-gradient(
          circle at $dotSize,
          #e8e6df $dotSize / 2,
          rgba(255, 255, 255, 0) $dotSize
        ),
        radial-gradient(
          circle,
          #e8e6df $dotSize / 2,
          rgba(255, 255, 255, 0) $dotSize
        );
      background-position: top;
      background-size: $dotSize * 4.1 $dotSize * 2, 0px 0px;
      background-repeat: repeat-x;
    }
  }

  &-input {
    padding: 15px 23px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #7299c6;
    width: calc(100% - 90px);
    background: $bodyColor;
    font-family: "Brandon-Grotesque";
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    display: block;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 30px;
    color: #3f281e;
    outline: -webkit-focus-ring-color auto 0px !important;
    transition: border 0.3s;
    @include md {
      font-size: 14px;
      padding: 9px 18px;
      width: 100%;
    }
    &::placeholder {
      color: #e8e6df;
    }

    &:not(:placeholder-shown) {
      border: 1px solid #3f281e;
    }
  }

  &-flex {
    display: flex;
  }
  &-step {
    padding-right: 20px;
    @include md {
      padding-right: 9px;
    }
  }

  &-buttonCon {
    @extend %flexContent-spaceBetween;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $slidePadding;
    z-index: 10;

    @include md {
      padding: 20px 0px 10px;
      position: relative;
    }
    &.--inLine {
      @include md {
        display: none;
      }
    }

    &.--fixed {
      display: none;
      @include md {
        width: 100vw;
        max-width: 100%;
        padding: 20px 35px;
        background: $whiteColor;
        display: flex;
        transition: transform 0.3s;
      }
    }
  }

  &-ingredientCon {
    width: calc(100% + 60px);
    margin-left: -30px;
    height: calc(100% - 70px);
    padding-top: 60px;
    margin-top: -60px;
    position: relative;
    padding-bottom: 50px;

    @include md {
      width: calc(100%);
      margin-left: 0;
      &.--noPag {
        padding-bottom: 0px;
        pointer-events: none;
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 40px;
      @extend %flexContent-center;

      @include md {
        bottom: -5px;
        flex-wrap: nowrap;
        width: 100%;
        padding: 0 10px;
      }
    }

    .swiper-pagination-bullet {
      position: relative;
      width: 10px;
      height: 10px;
      margin: 0 12px;
      height: 10px;

      border-radius: 100%;
      transition: background 0.3s;
      background: #e8e6df;
      display: block;
      @extend %flexContent-center;
      @include md {
        width: 100%;
        max-width: 8px;
        height: 8px;
        margin: 8px;
        // background: unset;
        // &:after {
        //   display: block;
        //   content: "";
        //   border-radius: 100%;
        //   background: #e8e6df;
        //   margin: auto;
        //   height: 8px;
        //   width: 8px;
        // }
      }
    }

    .swiper-pagination-bullet-active {
      background: red;
      background: #848484;
      @include md {
        // background: unset;
        &:after {
          background: #848484;
        }
      }
    }
    .swiper-pagination-lock {
      display: none;
    }
  }

  &-ingredientSlide {
    $columSize: 31%;
    grid-column-gap: 5%;
    grid-row-gap: 4%;
    padding: 0px 6% 0%;
    min-height: 220px;

    display: grid;
    grid-template-columns: $columSize $columSize $columSize;
    justify-content: center;
    height: calc(100% + 0px);
    width: 100% !important;
    pointer-events: none;
    @include md {
      min-height: unset;
      max-height: unset;
      grid-template-columns: 49% 49%;
      height: auto;
      grid-column-gap: 2%;
      padding: 0;
      &.--mobileFlow {
        // pointer-events: all;
        grid-row-gap: 4vw;
        grid-column-gap: 2vw;
      }
    }
  }

  &-ingredient {
    width: 100%;
    padding: 0px;
    margin-bottom: 7px;
    min-height: 155px;
    // border-radius: 20px;
    // overflow: hidden;
    text-align: center;
    position: relative;
    pointer-events: none;
    @include md {
      min-height: unset;
      max-height: unset;
      padding-bottom: 10px;
    }
  }

  %infoButtons {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 25px;
    height: 25px;
    background: white;
    border-radius: 100%;
  }

  &-ingredient {
    &ImgCon {
      position: relative;
      margin: auto;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      width: 51.4%;
      height: 0;
      padding-top: 51.4%;
      transition: transform 0.3s;

      // pseudo background in case we need to use the drop shadow filter
      // &::after {
      //   content: "";
      //   display: block;
      //   @extend %absolute-cover;
      //   background: #f8f5e2;
      //   z-index: -8;
      //   clip-path: $sealPath;
      // }

      @media only screen and (max-height: 1010px) {
        $width: 9vh;
        width: $width;
        height: 0;
        padding-top: $width;
      }
      @include md {
        width: 68%;
        height: 0;
        padding-top: 68%;
      }
      z-index: -10;
      pointer-events: all;
    }
    &Ring {
      @extend %absolute-cover;
      content: "";
      display: block;
      z-index: -9;
      path {
        fill: $redColor;
      }

      $activeOffset: 5px;
      top: -$activeOffset;
      left: -$activeOffset;
      height: calc(100% + #{$activeOffset * 2});
      width: calc(100% + #{$activeOffset * 2});
      transform: scale(0.8);
      transition: transform 0.3s;
    }
    &Overlay {
      @extend %absolute-cover;
      @extend %flexContent-center;
      background: rgba(63, 40, 30, 0.75);
      clip-path: $sealPath;
      z-index: 10;
      color: $whiteColor;
      font-family: "Brandon-Grotesque";
      font-weight: 400;
      font-size: 6vh;
      line-height: 100%;
      opacity: 0;
      transition: opacity 0.3s;
      // outline: 1px solid transparent;
      // border-radius: 4px;
      // overflow: hidden;
      border-radius: 50%;

      @include md {
        font-size: 15vw;
      }
    }

    %quantityButton {
      width: 35px;
      height: 35px;
      @include md {
        width: 30px;
        height: 30px;
      }
      background: $redColor;
      border-radius: 100%;
      color: white;
      font-size: 30px;
      line-height: 20px;
      font-family: "Brandon-Grotesque";
      font-weight: 900;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      z-index: 11;
      pointer-events: none;
      opacity: 0;
      transition: all 0.3s;
    }
    &AddButton {
      @extend %quantityButton;
      @extend %flexContent-center;
      right: 0px;
    }
    &RemoveButton {
      @extend %quantityButton;
      @extend %flexContent-center;
      left: 0px;
    }
    &Img {
      @extend %absolute-cover;
      clip-path: $sealPath;

      padding: 10px;
      object-fit: cover;
      background: #f8f5e2;
      // filter: drop-shadow(0 0 0.2rem rgba(black, 0.2));
    }

    img {
    }
    &Title {
      padding-top: 9px;
      // padding-bottom: 7px;
      color: $brownColor;
      font-family: "Brandon-Grotesque";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;

      pointer-events: all;
      max-width: 177px;
      margin: auto;

      @include md {
        line-height: 120%;
        padding-top: 10px;
        font-size: 14px;
      }

      svg {
        margin-left: 10px;
        transform: translateY(20%);
      }
    }
    &Cost {
      font-family: "Brandon-Grotesque";
      font-style: bold;
      font-weight: 700;
      color: $brownMDColor;
      font-size: 14px;
      line-height: 17px;
      padding-top: 2px;
      text-align: center;
      letter-spacing: 0.25px;
      @include md {
        line-height: unset;

        padding-top: 2px;
      }
    }
    &Info {
      @extend %infoButtons;
      right: 0;
    }
    &Price {
      @extend %infoButtons;
      left: 0;
    }
  }

  &-backgroundModel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: $brownBGColor;
    z-index: 12;
    @extend %flexContent-center;

    .configurator {
      &-slide {
        height: unset;

        border-radius: 10px;
        width: calc(100% - 100px);
        @include md {
          width: 96%;
          max-width: 600px;
        }
        &Title {
          text-align: center;
        }
        &Copy {
        }
      }

      &-buttonCon {
        position: relative;
        padding: 22px 5px;
        @include md {
          padding: 20px 0px 0px;
          position: relative;
        }
      }
    }
  }
}

.configurator-slide {
  &.--namer {
    .configurator {
      &-slideTitle {
        text-align: center;
      }
      &-slideCopy {
        text-align: center;
      }
      &-flex {
        margin: auto;
        display: block;
      }
    }
  }
}

.active.configurator-ingredient {
  .configurator {
    &-ingredient {
      &Ring {
        transform: scale(1);
      }
      &Overlay {
        opacity: 1;
      }

      $selectedOffset: 23px;

      @include md {
        $selectedOffset: 19px;
      }
      &AddButton {
        opacity: 1;

        right: -$selectedOffset;
        pointer-events: all;
      }
      &RemoveButton {
        opacity: 1;

        left: -$selectedOffset;
        pointer-events: all;
      }
    }
  }
}

.configurator-addon {
  &Con {
    @extend %flexContent-center;
    overflow: hidden;
    margin: 0 50px;
    border-radius: 5px;
    height: 20vh;
    position: relative;
    z-index: 2;
    background: $bodyColor;
    border: 1px solid #f8f5e2;
    @include md {
      margin: 0;
      height: 200px;
    }
    .buttonMain {
      margin: auto;
      margin-top: 26px;
      @include md {
        margin-top: 20px;
      }
    }
  }
  &BG {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  &Title {
    text-align: center;
    font-family: "Goudy-Sans";

    color: $redColor;
    margin-bottom: 2px;
  }
  &Copy {
    font-family: "Brandon-Grotesque";

    font-weight: 300;
    letter-spacing: 0.25px;
    color: #848484;

    @include md {
      font-size: 11px;
      text-align: center;
      max-width: 200px;
    }
  }
  &Text {
    color: $redColor;
    font-family: "Brandon-Grotesque";
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.25px;
    padding-top: 8px;
  }
}

#modal {
  position: fixed;
  top: -100%;
  left: -100%;
  z-index: 99;

  .configurator-ingredientRemoveButton,
  .configurator-ingredientAddButton {
    display: none;
  }
}
.--shell {
  .configurator-ingredientRemoveButton,
  .configurator-ingredientAddButton {
    display: none;
  }
}

#buttoncon {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 400%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: flex;
}

.modelSmall {
  background: $brownMDColor;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  z-index: 11;
  left: 0;
  right: 0;
  bottom: 50%;
  font-size: 16px;
  line-height: 22px;
  color: #fffdef;
  max-width: 300px;
  width: calc(100%);
  text-align: left;
  padding: 15px;
  padding-right: 35px;
  transition: all 0.3s;
  font-family: "Brandon-Grotesque";
  font-weight: 400;
  pointer-events: none;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: $brownMDColor;
    transform: scaleX(0.7);
    border-bottom: 0;
    margin-left: -15px;
    margin-bottom: -13px;
  }
  &-x {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 99;
  }

  @include md {
    font-size: 13px;
    max-width: 200px;
    width: calc(80vw);
    padding-right: 35px;

    &:after {
      left: 25%;
    }
  }
}

.configurator-ingredient {
  &:nth-of-type(even) {
    .modelSmall {
      left: unset;
      right: 0;

      &:after {
        left: unset;
        right: 25%;
      }
    }
  }
}
