.popup {
  &-bg {
    @extend %flexContent-center;
    background: $brownBGColor;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;
  }
  &-con {
    padding: 50px;
    background: $whiteColor;
    max-width: 500px;
    border-radius: 10px;
    @include md {
      padding: 30px;
      margin: 10px;
    }
  }
  &-title {
    font-family: "Goudy-Sans";
    font-size: 28px;
    line-height: 30px;
    letter-spacing: 0.25px;
    color: $redColor;
    @include md {
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      padding: 0 0px;
    }
  }
  &-copy {
  }
  &-buttonCon {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @include md {
      margin-top: 30px;
    }
  }
}
