.checkout {
  &-con {
    min-height: calc(var(--vh, 1vh) * 100);
    @extend %flexContent-center;
    @include md {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .buttonMain {
      margin-top: 53px !important;

      @include md {
        margin-top: 33px !important;
      }
      .buttonMain-inner {
        font-size: 28px;

        @include md {
          font-size: calc(5vw + 2px);
        }
      }
    }
  }

  &-link {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 0.25px;
    padding: 20px 0 0;
    color: $blueColor;
  }

  &-inner {
    display: grid;
    // display: nones;
    place-items: center;
    padding: 60px;
    background: $whiteColor;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    width: 633px;
    @include md {
      width: 90%;
    }

    &.--placed {
      width: 700px;
      padding: 70px 85px;

      @include md {
        width: 92%;
        padding: 40px 20px;
        margin: auto;
      }
    }
  }
  &-title {
    // max-width: 400px;
    padding-bottom: 20px;
    span {
      animation: bounce 1.9s ease-in-out infinite;
      position: relative;
      padding: 1px;

      &:nth-child(1) {
        padding-left: 4px;
      }

      @for $i from 1 through 9 {
        &:nth-child(#{$i}) {
          animation-delay: 0.3s * $i;
        }
      }
    }
    &H3 {
      color: $brownColor;
      padding-top: 35px;

      padding-bottom: 16px;
    }
  }
  &-minutes {
    padding-top: 7px;
    font-weight: 900;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: $redColor;

    @include md {
      font-size: 24px;
      line-height: 34px;
    }
  }

  &-orderNumber {
    color: $brownColor;
    letter-spacing: 0.5px;
    font-size: 20px;
    line-height: 29px;
    font-weight: 700;
    padding-top: 7px;
    padding-bottom: 9px;

    @include md {
      padding-top: 3px;
      padding-bottom: 9px;
      font-size: 16px;
    }
  }
  &-copy {
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.5px;
    max-width: 530px;
    @include md {
      padding: 0px 10px;
      font-size: 16px;
      line-height: 23px;
    }
  }
  &-cardIcon {
    padding: 10px;

    // background: grey;
    // height: 200px;
    // width: 200px;
  }
}

@keyframes bounce {
  20% {
    opacity: 1;
    bottom: 0px;
  }
  50% {
    bottom: 10px;
  }
  80% {
    opacity: 1;
    bottom: 0px;
  }
}

.checkout-input {
  position: relative;
  &.--inLine {
    display: flex !important;
  }

  &-flex {
    width: 100%;
    max-width: 423px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    z-index: 999;
    position: relative;

    // align-items: center;

    &.--center {
      display: flex !important;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: -20px;
    }
  }

  &-checkbox {
    -webkit-appearance: none;
    background-color: $bodyColor !important;
    border: 1px solid $blueColor;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    margin-right: 15px;
    margin-top: 0px;
    border-radius: 3px;
    display: inline-block;
    position: relative;

    &:active,
    &:checked:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px 1px 3px rgba(0, 0, 0, 0.1);
    }

    &:checked {
      background-color: $blueColor !important;
      border: 1px solid $blueColor;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
        inset 15px 10px -12px rgba(255, 255, 255, 0.1);
      color: $bodyColor;
    }

    &:checked:after {
      content: "\2714";
      font-size: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $bodyColor;
    }
  }

  &-title {
    text-align: left;
    margin: 0;
    width: 100%;
    max-width: 423px;
    display: block;
    margin-bottom: -15px;

    &:not(:first-of-type) {
    }
  }

  &-errorMsg {
    color: $redColor;
    width: 300px;
    text-align: right;
    display: block;
    margin-bottom: -15px;
  }

  &.configurator-buttonCon {
    // justify-content: center;
  }

  input {
    margin-bottom: 0px;
  }

  .--smaller {
    max-width: 400px;
  }

  .buttonMain {
    .buttonMain-inner {
      font-size: 12px !important;
    }

    &.--center {
      display: flex !important;
      svg {
        path {
          fill: $blueColor !important;
        }
      }
    }
  }
}
