header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 834px;
  margin: auto;
  z-index: 10;
}

.headerTop {
  background: $whiteColor;
  width: 100%;
  height: 85px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);

  @include md {
    height: 85px - 8;
  }

  @extend %flexContent-center;
  &-logo {
    color: white;
    text-align: center;
    margin-bottom: -8px;

    &Inner {
      height: 62px;
      @include md {
        height: 39px;
      }
    }
  }

  &-cart {
    position: absolute;
    right: 50px;
    padding: 20px;
    color: white;

    @include md {
      right: 10px;
      padding: 20px;
    }

    &Icon {
      margin-top: 6px;
      @include md {
        svg {
          height: 24px;
          width: 24px;
        }
      }
      &.--active {
        margin-top: 3px;
        @include md {
          svg {
            height: 33px;
            width: 33px;
          }
        }
      }
    }

    &Count {
      z-index: 2;
      margin-top: 3.9px;
      width: 10px;
      text-align: center;
      left: 29.5%;
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: $bodyColor;
      font-family: "Brandon-Grotesque";
      font-weight: 900;
      @include md {
        z-index: 2;
        margin-top: 0px;
        width: 5px;
        text-align: center;
        left: 32.5%;
        position: absolute;
        font-size: 10px;
        line-height: 20px;
        letter-spacing: 0.5px;
        color: $bodyColor;
        font-family: "Brandon-Grotesque";
        font-weight: 900;
      }
    }
  }

  &:after {
    $size: 8px;
    background: linear-gradient(135deg, $whiteColor $size, transparent 0),
      linear-gradient(-135deg, $whiteColor $size, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: $size * 2 $size * 2;
    content: " ";
    display: block;
    position: absolute;
    bottom: -$size * 2 + 1;
    left: 0px;
    width: 100%;
    height: $size * 2;
  }

  &-backButton {
    position: absolute;
    left: 50px;
    padding: 20px;
    font-weight: 900;
    letter-spacing: 1px;
    font-size: clamp(9px, 14px, calc(2vw + 9px));
    text-transform: uppercase;
    color: $blueColor;
    display: flex;
    align-items: center;
    @include md {
      line-height: 13px;
      font-size: min(calc(5vw + 9px), 9px);
      max-width: 120px;
      left: 7px;
      svg {
        padding-right: 10px;
        height: 13px;
      }
    }
  }
  svg {
    padding-right: 10px;
  }
}

// if in configurator

.headerProgress {
  //   background: $whiteColor;
  transition: transform 0.3s;
  padding: 30px 60px 20px;
  @include md {
    padding: 20px 28px 30px;
  }
  &-titleCon {
    @extend %flexContent-spaceBetween;
    padding: 0px 20px;
    @include md {
      padding: 0px;
      height: 30px;
    }
  }
  &-thirdsCon {
    @extend %flexContent-center;
    width: 30%;
    @include md {
      width: unset;
      min-width: 30px;
    }
  }
  &-stepCount {
    @extend %flexContent-center;
    $circleSize: 21px;
    height: $circleSize;
    width: $circleSize;
    margin: 0 10px;
    border-radius: 100%;
    background: $blueColor;
    color: $whiteColor;
    font-family: "Brandon-Grotesque";
    font-weight: 900;
    font-size: 13px;
    line-height: 100%;
    text-align: center;
    clip-path: polygon(
      94% 47.5%,
      97.84% 40.23%,
      98.58% 37.63%,
      98.23% 35%,
      96.84% 32.66%,
      94.63% 31%,
      87.07% 27.27%,
      85.07% 25.6%,
      84.07% 23.23%,
      82.71% 15.16%,
      79.84% 10.7%,
      74.52% 9.46%,
      66% 10.69%,
      63.33% 10.49%,
      61.05% 9.15%,
      55% 3.4%,
      44.88% 3.4%,
      38.88% 9.15%,
      36.59% 10.49%,
      33.91% 10.69%,
      25.48% 9.44%,
      20.17% 10.68%,
      17.3% 15.14%,
      15.94% 23.21%,
      14.94% 25.58%,
      12.94% 27.25%,
      5.3% 31%,
      1.75% 35%,
      2.18% 40.25%,
      6% 47.5%,
      6.63% 50%,
      6% 52.51%,
      2.18% 59.79%,
      1.75% 65%,
      5.3% 69%,
      12.87% 72.73%,
      14.87% 74.4%,
      15.87% 76.77%,
      17.23% 84.83%,
      20.1% 89.29%,
      25.41% 90.52%,
      33.82% 89.28%,
      36.5% 89.48%,
      38.79% 90.83%,
      44.79% 96.58%,
      54.91% 96.58%,
      60.91% 90.83%,
      63.19% 89.48%,
      65.86% 89.28%,
      74.27% 90.52%,
      79.59% 89.3%,
      82.46% 84.83%,
      84% 76.81%,
      85% 74.43%,
      87% 72.77%,
      94.63% 69%,
      96.84% 67.35%,
      98.23% 65.01%,
      98.58% 62.34%,
      97.84% 59.75%,
      94% 52.51%,
      94% 47.51%
    );

    @include md {
      margin: 10px;
      width: 19px;
      height: 19px;
    }
  }
  &-title {
    font-family: "Brandon-Grotesque";
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    color: $brownColor;
    // transition: all 0.3s;
    white-space: nowrap;
    @include md {
      font-size: 14px;
    }
  }

  &-bar {
    margin-top: 10px;
    width: 100%;
    background: #e8e6df;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
  }

  &-barInner {
    content: "";
    transition: width 0.7s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: $blueColor;
    border-radius: 10px;
  }
}
