$headerHeight: 80px;
$headerHeight--steps: 172px;

$headerHeight-instructions: 50px;

//colors
$whiteColor: white;
$bodyColor: #fffdef;
$blueColor: #7299c6;
$redColor: #e31b23;
$brownColor: #3f281e;
$brownMDColor: #b88b74;
$brownBGColor: rgba(63, 40, 30, 0.75);

//paths

$chevronPathLeft: polygon(
  8.1px 0px,
  158.5px 0px,
  159.2px 0.2px,
  159.7px 0.7px,
  159.9px 1.4px,
  159.8px 2.1px,
  152.7px 19.6px,
  152.7px 20.7px,
  159.8px 38.2px,
  159.9px 38.9px,
  159.7px 39.6px,
  159.2px 40.1px,
  158.5px 40.3px,
  8.1px 40.3px,
  7.3px 40px,
  6.8px 39px,
  0.1px 20.5px,
  0.1px 19.5px,
  6.8px 0.9px,
  7.3px 0.2px,
  8.1px 0px
);

$chevronPathRight: polygon(
  151.875px 40px,
  1.46px 40px,
  0.772px 39.834px,
  0.245px 39.362px,
  0.007px 38.699px,
  0.114px 38.002px,
  7.258px 20.54px,
  7.258px 19.46px,
  0.114px 1.998px,
  0.007px 1.302px,
  0.245px 0.638px,
  0.772px 0.166px,
  1.46px 0px,
  151.875px 0px,
  152.718px 0.279px,
  153.231px 0.999px,
  159.904px 19.541px,
  159.904px 20.54px,
  153.231px 39.081px,
  152.698px 39.752px,
  151.875px 40px
);
$chevronPathRightBig: polygon(
  284.765px 74.9999px,
  2.73818px 74.9999px,
  1.44679px 74.6882px,
  0.459214px 73.8041px,
  0.0123445px 72.5597px,
  0.213585px 71.2536px,
  13.6093px 38.5115px,
  13.6093px 36.4885px,
  0.213585px 3.74636px,
  0.0123445px 2.44035px,
  0.459214px 1.19597px,
  1.44679px 0.311827px,
  2.73818px 0.00011639px,
  284.765px 0.00011639px,
  286.346px 0.522588px,
  287.309px 1.87324px,
  299.819px 36.6384px,
  299.819px 38.5115px,
  287.309px 73.2766px,
  286.309px 74.5349px,
  284.765px 74.9999px
);

$sealPath: polygon(
  98.26% 53.59%,
  96.46% 51.2%,
  96.46% 48.41%,
  98.22% 46%,
  97.32% 39.89%,
  94.93% 38.12%,
  94.12% 35.42%,
  95.12% 32.64%,
  95% 29.33%,
  92.54% 27.05%,
  89.74% 26.05%,
  88.57% 25.13%,
  88.19% 23.7%,
  88.39% 20.7%,
  87.3% 17.54%,
  84.3% 16.06%,
  81.3% 15.84%,
  79.93% 15.29%,
  79.16% 14%,
  78.5% 11.11%,
  76.5% 8.4%,
  73.19% 7.83%,
  70.28% 8.47%,
  67.69% 7.31%,
  66.29% 4.77%,
  63.64% 2.77%,
  60.31% 3.17%,
  57.87% 4.46%,
  56.4% 4.73%,
  55.07% 4.07%,
  53.07% 2.12%,
  46.84% 2.12%,
  44.75% 4.21%,
  43.42% 4.88%,
  42% 4.62%,
  39.32% 3.2%,
  36% 2.85%,
  33.35% 5%,
  31.92% 7.6%,
  30.82% 8.6%,
  29.35% 8.76%,
  26.43% 8.1%,
  21.19% 11.37%,
  20.55% 14.27%,
  19.79% 15.54%,
  18.42% 16.12%,
  15.42% 16.34%,
  12.42% 17.85%,
  11.37% 21%,
  11.58% 24%,
  10.05% 26.37%,
  7.27% 27.38%,
  4.81% 29.67%,
  4.7% 33%,
  5.7% 35.77%,
  5.7% 37.21%,
  4.9% 38.41%,
  2.6% 40.2%,
  1.46% 41.47%,
  0.9% 43.08%,
  1% 44.78%,
  1.75% 46.31%,
  3.53% 48.69%,
  4% 50.09%,
  3.54% 51.5%,
  1.75% 53.88%,
  2.65% 59.98%,
  5% 61.77%,
  5.9% 63%,
  5.85% 64.48%,
  4.85% 67.26%,
  5% 70.58%,
  7.47% 72.85%,
  10.27% 73.85%,
  11.8% 76.21%,
  11.59% 79.26%,
  12.67% 82.42%,
  15.67% 83.91%,
  18.67% 84.1%,
  20% 84.7%,
  20.8% 86%,
  21.45% 88.9%,
  26.71% 92.17%,
  29.63% 91.53%,
  31.11% 91.69%,
  32.2% 92.69%,
  33.66% 95.27%,
  39.66% 96.91%,
  42.1% 95.59%,
  43.57% 95.32%,
  44.92% 95.98%,
  46.92% 97.91%,
  53.14% 97.91%,
  55.27% 95.83%,
  58% 95.38%,
  60.63% 96.79%,
  66.63% 95.05%,
  68% 92.46%,
  69.1% 91.46%,
  70.59% 91.31%,
  73.5% 91.92%,
  76.79% 91.34%,
  78.73% 88.65%,
  79.37% 85.76%,
  80.13% 84.48%,
  81.51% 83.91%,
  84.51% 83.68%,
  87.51% 82.17%,
  88.57% 79%,
  88.35% 76%,
  89.89% 73.63%,
  92.67% 72.63%,
  95.12% 70.35%,
  95.24% 67%,
  94.24% 64.22%,
  95.03% 61.52%,
  97.41% 59.72%,
  98.56% 58.45%,
  99.14% 56.84%,
  99.04% 55.13%,
  98.26% 53.59%
);
