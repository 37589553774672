@use "sass:math";
html {
  background: $bodyColor;
  height: fit-content;
  display: block;
  // scroll-behavior: smooth;
}
body {
  height: 100%;
  max-width: 850px;
  overflow: hidden;
  margin: auto;
  user-select: none;
  font-family: "Brandon-Grotesque";
}

h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: $redColor;
  font-family: "MagnesiumSTD";
  @include md {
    font-size: 24px;

    font-size: min(calc(8.8vw - 9px), 27px);
    line-height: 120%;
    letter-spacing: 0.5px;
  }
}

h3 {
  font-size: 20px;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.25px;
  margin-bottom: 3px;
  margin-top: 15px;
  color: $redColor;

  font-family: "Goudy-Sans";

  @include md {
    font-size: min(calc(6.6vw - 8px), 20px);
    margin-top: 0px;
    line-height: 120%;

    margin: 0;
  }
}

a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
}